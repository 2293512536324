import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import reportWebVitals from './reportWebVitals';
import AppRouter from './router/AppRouter';
import CurrentSessionProvider from './contexts/providers/CurrentSessionProvider';
import 'services/i18n';
import '@fontsource/roboto';

ReactDOM.render(
	<Suspense fallback={<p>Loading</p>}>
		<React.StrictMode>
			<CurrentSessionProvider>
				<AppRouter />
			</CurrentSessionProvider>
		</React.StrictMode>
	</Suspense>,

	document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
