import React from 'react';
import { Checkbox, FormControlLabel } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const CheckboxField = ({ value, onChange, disabled, fieldName }) => {
	const { t, i18n } = useTranslation();

	return (
		<FormControlLabel
			control={
				<Checkbox
					checked={value}
					onChange={(e) => {
						onChange(e.target.checked);
					}}
					name={fieldName}
					disabled={disabled}
				/>
			}
			disabled={disabled}
			label={t(`billingMethodsFields.${fieldName}`)}
			id={fieldName}
		/>
	);
};
export default CheckboxField;
