import React from 'react';
import getCurrentToken from '../../misc/getCurrentToken';
import { useState, useEffect } from 'react';
import CurrentSessionContext from '../CurrentSessionContext';

import { useReducer } from 'react';
import CurrentUserSessionDataContext from '../CurrentUserSessionDataContext';
import {
	currentUserSessionStreamDataReducer,
	INITIAL_CURRENT_USER_SESSION_DATA_REDUCER_STATE
} from '../../reducers/currentUserSessionDataReducer';
const CurrentUserSessionDataProvider = ({ children }) => {
	const [currentUserSessionData, dispatchCurrentUserSessionData] = useReducer(
		currentUserSessionStreamDataReducer,
		INITIAL_CURRENT_USER_SESSION_DATA_REDUCER_STATE
	);

	return (
		<CurrentUserSessionDataContext.Provider
			value={{ currentUserSessionData, dispatchCurrentUserSessionData }}
		>
			{children}
		</CurrentUserSessionDataContext.Provider>
	);
};

export default CurrentUserSessionDataProvider;
