import React from 'react';
import CurrentUserSessionDataProvider from '../../contexts/providers/CurrentUserSessionDataProvider';
import UserMainManager from './UserMainManager';

const UserMainContextRenderer = () => {
	return (
		<CurrentUserSessionDataProvider>
			<UserMainManager />
		</CurrentUserSessionDataProvider>
	);
};

export default UserMainContextRenderer;
