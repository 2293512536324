import React from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

const SwiftField = ({ value, onChange, disabled, fieldName }) => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<label htmlFor={fieldName}>{t(`billingMethodsFields.${fieldName}`)}</label>
			<input
				id={fieldName}
				type={'text'}
				value={value}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				disabled={disabled}
			/>
		</>
	);
};

export default SwiftField;
