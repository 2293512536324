import React, { useEffect } from 'react';
import Link from '@material-ui/core/Link';
import useAxios from '../../hooks/axios-hooks';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';
import { useTranslation } from 'react-i18next';

const BillingUserItemRenderer = ({ current }) => {
	const { eliminateBillingUser } = useCurrentUserSessionData();
	const { t, i18n } = useTranslation();

	const [
		{
			data: dataDisableBillingUser,
			error: errorDisableBillingUser,
			loading: loadingDisableBillingUser
		},
		executePostDisableBillingUser
	] = useAxios(
		{
			url: 'disableBillingUser',
			method: 'POST'
		},
		{ manual: true }
	);

	useEffect(() => {
		if (dataDisableBillingUser && dataDisableBillingUser.success) {
			eliminateBillingUser(dataDisableBillingUser.disabledBillingUserId);
		}
	}, [dataDisableBillingUser]);

	useEffect(() => {
		if (errorDisableBillingUser) {
			alert(t('BillingUsersManager.errorDisablingBillingUser'));
		}
	}, [errorDisableBillingUser]);
	const eliminateBillingUserId = (billingUserId) => {
		executePostDisableBillingUser({
			data: {
				id: billingUserId
			}
		});
	};

	return (
		<li key={current._id}>
			{t('BillingUsersManager.completeName')}: {current.lastName}, {current.firstName} (
			{t(`documentTypes.${current.identificationType}`)} :{current.identificationNumber}) -{' '}
			<Link
				to={'#'}
				onClick={(e) => {
					e.preventDefault();
					if (!loadingDisableBillingUser) eliminateBillingUserId(current._id);
				}}
				disabled={loadingDisableBillingUser}
			>
				{t('actions.delete')}
			</Link>
		</li>
	);
};

export default BillingUserItemRenderer;
