import React from 'react';
import EmailField from './fieldsRenderer/EmailField';
import TextField from './fieldsRenderer/TextField';
import NumberField from './fieldsRenderer/NumberField';
import IbanField from './fieldsRenderer/IbanField';
import ComboField from './fieldsRenderer/ComboField';
import SwiftField from './fieldsRenderer/SwiftField';
import CheckboxField from './fieldsRenderer/CheckboxField';

const BillingDataTypeConfigurationRenderer = ({
	billingDataTypeConfiguration,
	configurationObject,
	setConfigurationObject,
	disabled
}) => {
	if (
		!billingDataTypeConfiguration ||
		!billingDataTypeConfiguration.fields ||
		billingDataTypeConfiguration.fields.length <= 0
	)
		return <></>;

	return (
		<>
			{billingDataTypeConfiguration.fields.map((currentField) => {
				const fieldProps = {
					disabled: disabled,
					value: configurationObject[currentField.name],
					fieldName: currentField.name,
					onChange: (value) => {
						setConfigurationObject((prev) => {
							let newObject = { ...prev };
							newObject[currentField.name] = value;
							return newObject;
						});
					}
				};

				switch (currentField.inputType) {
					case 'email':
						return <EmailField {...fieldProps} />;

					case 'text':
						return <TextField {...fieldProps} />;

					case 'number':
						return (
							<NumberField
								{...fieldProps}
								min={currentField.min}
								max={currentField.max}
							/>
						);

					case 'iban':
						return <IbanField {...fieldProps} />;
					case 'swift':
						return <SwiftField {...fieldProps} />;
					case 'combo':
						return (
							<ComboField {...fieldProps} options={currentField.validValuesArray} />
						);

					case 'checkbox':
						return <CheckboxField {...fieldProps} />;

					default:
						return <></>;
				}
			})}
		</>
	);
};

export default BillingDataTypeConfigurationRenderer;
