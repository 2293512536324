import React from 'react';
import { Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const BillingDataItemBillingConfigurationItemRenderer = ({ billingConfigurationToRender }) => {
	const { t, i18n } = useTranslation();

	const fieldName = Object.keys(billingConfigurationToRender)[0];
	return (
		<Typography variant='body2' component='p'>
			{t(`billingMethodsFields.${fieldName}`)} : {billingConfigurationToRender[fieldName]}
		</Typography>
	);
};

export default BillingDataItemBillingConfigurationItemRenderer;
