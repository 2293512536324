import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';
import AddBillingUserForm from '../AddBillingUserForm/AddBillingUserForm';
import { useState } from 'react';

import { Button, Paper } from '@material-ui/core';
import BillingUserItemRenderer from '../BillingUserItemRenderer/BillingUserItemRenderer';
import notifyHeightToParent from '../../misc/notifyHeightToParent';

const BillingUsersManager = () => {
	const { t, i18n } = useTranslation();

	const { getBillingUsers } = useCurrentUserSessionData();

	const [isAddBillingUserFormVisible, setIsAddBillingUsersFormVisible] = useState(false);

	useEffect(() => {
		notifyHeightToParent();
	}, [isAddBillingUserFormVisible]);
	return (
		<Paper elevation={3}>
			<h3>{t('BillingUsersManager.title')}</h3>
			<ul>
				{getBillingUsers().map((current) => {
					return <BillingUserItemRenderer current={current} key={current._id} />;
				})}
			</ul>
			<Button
				onClick={(e) => {
					e.preventDefault();
					setIsAddBillingUsersFormVisible((prev) => {
						return !prev;
					});
				}}
			>
				{t('BillingUsersManager.createBillingUser')}
			</Button>

			{isAddBillingUserFormVisible && (
				<AddBillingUserForm
					onSuccess={() => {
						setIsAddBillingUsersFormVisible(false);
					}}
				/>
			)}
		</Paper>
	);
};
export default BillingUsersManager;
