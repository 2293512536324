import React, { useEffect, useMemo, useState, useRef } from 'react';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';
import { Button, Checkbox, Container, FormControlLabel } from '@material-ui/core';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';
import countryList from 'react-select-country-list';
import useAxios from '../../hooks/axios-hooks';
import MaterialUiPhoneNumber from 'material-ui-phone-number';
import checkIfCountryCodePresentInCountryCodesObjectArray from '../../misc/checkIfCountryCodePresentInCountryCodesObjectArray';
import BillingDataTypeConfigurationRenderer from '../BillingDataTypeConfigurationRenderer/BillingDataTypeConfigurationRenderer';
import notifyHeightToParent from '../../misc/notifyHeightToParent';
const organizationTypes = [
	'individual',
	'c-corporation',
	's-corporation',
	'partnership',
	'trust-estate',
	'llc-c-corporation',
	'llc-s-corporation',
	'llc-partnership',
	'other'
];

const AddBillingDataForm = ({ onSuccess }) => {
	const { getBillingMethodTypes, getBillingUsers, addBillingData } = useCurrentUserSessionData();
	const { t, i18n } = useTranslation();
	const countryOptions = useMemo(() => countryList().getData(), []);
	const [countryValue, setCountryValue] = useState(countryOptions[0].value);
	const [addressValue, setAddressValue] = useState('');
	const [cityValue, setCityValue] = useState('');
	const [stateValue, setStateValue] = useState('');
	const [zipCodeValue, setZipCodeValue] = useState('');
	const [phoneNumberValue, setPhoneNumberValue] = useState('');
	const [isUSCitizenValue, setIsUSCitizenValue] = useState(false);
	const [fiscalNameValue, setFiscalNameValue] = useState('');
	const [fiscalIdValue, setFiscalIdValue] = useState('');

	const [relatedBillingUsersOptions, setRelatedBillingUsersOptions] = useState([]);
	const [relatedBillingUserOption, setRelatedBillingUserOption] = useState();

	const [billingMethodTypesOptions, setBillingMethodTypeOptions] = useState([]);
	const [billingMethodTypeOptionValue, setBillingMethodTypeOptionValue] = useState();
	const [organizationTypeValue, setOrganizationTypeValue] = useState();
	const [dataPrivacyAcceptedValue, setDataPrivacyAcceptedValue] = useState(false);
	const [
		USCitizenOrOtherRecognisedConditionAcceptedValue,
		setUSCitizenOrOtherRecognisedConditionAcceptedValue
	] = useState(false);
	const [
		foreignAndExceptedOfUSATaxesAcceptedValue,
		setForeignAndExceptedOfUSATaxesAcceptedValue
	] = useState(false);
	const [correctFiscalIdAcceptedValue, setCorrectFiscalIdAcceptedValue] = useState(false);
	const [signatureValue, setSignatureValue] = useState('');
	const [
		noRestrictionsDueToLatePaymentsAcceptedValue,
		setNoRestrictionsDueToLatePaymentsAcceptedValue
	] = useState(false);
	const billingMethodTypesConfigurationsRef = useRef({});
	const [billingMethodConfigurationObject, setBillingMethodConfigurationObject] = useState({});
	const [{ data, error, loading }, executePost] = useAxios(
		{
			url: 'createBillingData',
			method: 'POST'
		},
		{ manual: true }
	);

	useEffect(() => {
		notifyHeightToParent();
	}, [billingMethodTypeOptionValue]);
	useEffect(() => {
		if (error) {
			alert(t('AddBillingDataForm.error'));
		}
	}, [error]);

	useEffect(() => {
		if (data && data.success && data.newBillingData) {
			addBillingData(data.newBillingData);
			onSuccess();
		}
	}, [data]);
	const getOrganizationTypeOptions = () => {
		let organizationTypesOptions = [];

		organizationTypes.forEach((current) => {
			organizationTypesOptions = [
				...organizationTypesOptions,
				{
					value: current,
					label: t(`AddBillingDataForm.availableOrganizationTypes.${current}`)
				}
			];
		});

		return organizationTypesOptions;
	};
	useEffect(() => {
		const receivedBillingUsers = getBillingUsers();
		let resultingBillingUsersOptions = [];
		if (receivedBillingUsers && receivedBillingUsers.length > 0) {
			receivedBillingUsers.forEach((current) => {
				resultingBillingUsersOptions = [
					...resultingBillingUsersOptions,
					{
						value: current._id,
						label:
							current.lastName +
							', ' +
							current.firstName +
							' - ' +
							t(`documentTypes.${current.identificationType}`) +
							': ' +
							current.identificationNumber
					}
				];
			});
		}

		setRelatedBillingUsersOptions(resultingBillingUsersOptions);
	}, [getBillingUsers()]);

	useEffect(() => {
		notifyHeightToParent();

		const receivedBillingMethodTypes = getBillingMethodTypes();
		let resultingBillingMethodTypes = [];
		if (receivedBillingMethodTypes && receivedBillingMethodTypes.length > 0) {
			receivedBillingMethodTypes.forEach((current) => {
				console.log(current);
				let isCurrentMethodAvailable = true;
				if (
					current.onlyAllowedToCountries &&
					current.onlyAllowedToCountries.length > 0 &&
					!checkIfCountryCodePresentInCountryCodesObjectArray(
						countryValue,
						current.onlyAllowedToCountries
					)
				) {
					isCurrentMethodAvailable = false;
				}
				if (
					isCurrentMethodAvailable &&
					current.disallowedCountries &&
					current.disallowedCountries.includes(countryValue)
				) {
					isCurrentMethodAvailable = false;
				}
				if (isCurrentMethodAvailable) {
					billingMethodTypesConfigurationsRef.current[current._id] = current;
					let labelToDisplay = t(`billingMethods.${current.name}`);
					if (current.isRecommended) {
						labelToDisplay =
							labelToDisplay +
							' (' +
							t('AddBillingDataForm.recomendedBillingMethod') +
							')';
					}
					resultingBillingMethodTypes = [
						...resultingBillingMethodTypes,
						{
							value: current._id,
							label: labelToDisplay
						}
					];
				}
			});
		}
		console.log(resultingBillingMethodTypes);
		setBillingMethodTypeOptions(resultingBillingMethodTypes);
	}, [getBillingMethodTypes(), countryValue]);

	useEffect(() => {
		getBillingMethodTypes();
	}, []);
	const onSubmit = (e) => {
		executePost({
			data: {
				billingUser: relatedBillingUserOption,
				countryCode: countryValue,
				state: stateValue,
				city: cityValue,
				address: addressValue,
				zipCode: zipCodeValue,
				phoneNumber: phoneNumberValue,
				isUSCitizen: isUSCitizenValue,
				fiscalName: fiscalNameValue,
				fiscalId: fiscalIdValue,
				organizationType: organizationTypeValue,
				dataPrivacyAccepted: dataPrivacyAcceptedValue,
				foreignAndExceptedOfUSATaxesAccepted: foreignAndExceptedOfUSATaxesAcceptedValue,
				correctFiscalIdAccepted: correctFiscalIdAcceptedValue,
				noRestrictionsDueToLatePaymentsAccepted: noRestrictionsDueToLatePaymentsAcceptedValue,
				USCitizenOrOtherRecognisedConditionAccepted: USCitizenOrOtherRecognisedConditionAcceptedValue,
				billingMethodType: billingMethodTypeOptionValue,
				billingMethodConfiguration: { ...billingMethodConfigurationObject },
				signature: signatureValue
			}
		});
		e.preventDefault();
	};
	if (relatedBillingUsersOptions.length === 0)
		return <p>{t('AddBillingDataForm.missingRelatedBillingUsers')}</p>;
	return (
		<Container>
			<form onSubmit={onSubmit} style={{ display: 'grid' }}>
				<label htmlFor={'relatedBillingUser'}>
					{t('AddBillingDataForm.relatedBillingUser')}
				</label>
				<Dropdown
					options={relatedBillingUsersOptions}
					onChange={(e) => {
						setRelatedBillingUserOption(e.value);
					}}
					value={relatedBillingUserOption}
					id={'relatedBillingUser'}
					placeholder={t('common.selectOption')}
					disabled={loading}
				/>
				{relatedBillingUserOption && (
					<>
						<label htmlFor={'country'}>{t('AddBillingDataForm.country')}</label>
						<Dropdown
							options={countryOptions}
							onChange={(e) => {
								setCountryValue(e.value);
							}}
							value={countryValue}
							id={'country'}
							placeholder={t('common.selectOption')}
							disabled={loading}
						/>

						<label htmlFor={'state'}>{t('AddBillingDataForm.state')}</label>
						<input
							id={'state'}
							type={'text'}
							value={stateValue}
							onChange={(e) => {
								setStateValue(e.target.value);
							}}
							disabled={loading}
						/>

						<label htmlFor={'city'}>{t('AddBillingDataForm.city')}</label>
						<input
							id={'city'}
							type={'text'}
							value={cityValue}
							onChange={(e) => {
								setCityValue(e.target.value);
							}}
							disabled={loading}
						/>

						<label htmlFor={'address'}>{t('AddBillingDataForm.address')}</label>
						<input
							id={'address'}
							type={'text'}
							value={addressValue}
							onChange={(e) => {
								setAddressValue(e.target.value);
							}}
							disabled={loading}
						/>
						<label htmlFor={'zipCode'}>{t('AddBillingDataForm.zipCode')}</label>
						<input
							id={'zipCode'}
							type={'number'}
							value={zipCodeValue}
							onChange={(e) => {
								setZipCodeValue(e.target.value);
							}}
							disabled={loading}
						/>

						<label htmlFor={'phoneNumber'}>{t('AddBillingDataForm.phoneNumber')}</label>

						<MaterialUiPhoneNumber
							defaultCountry={countryValue.toLowerCase()}
							onChange={(value) => {
								setPhoneNumberValue(value);
							}}
						/>

						<label htmlFor={'isUSCitizen'}>
							{t('AddBillingDataForm.isUSCitizenLabel')}
						</label>

						<FormControlLabel
							control={
								<Checkbox
									checked={isUSCitizenValue}
									onChange={(e) => {
										setIsUSCitizenValue(e.target.checked);
									}}
									name='USCitizenOrOtherRecognisedConditionAccepted'
									disabled={loading}
								/>
							}
							disabled={loading}
							label={t('AddBillingDataForm.isUSCitizen')}
							id={'isUSCitizen'}
						/>

						{!!isUSCitizenValue && (
							<>
								<label htmlFor={'fiscalName'}>
									{t('AddBillingDataForm.fiscalName')}
								</label>
								<input
									id={'fiscalName'}
									type={'text'}
									value={fiscalNameValue}
									onChange={(e) => {
										setFiscalNameValue(e.target.value);
									}}
									disabled={loading}
								/>

								<label htmlFor={'fiscalId'}>
									{t('AddBillingDataForm.fiscalId')}
								</label>
								<input
									id={'fiscalId'}
									type={'text'}
									value={fiscalIdValue}
									onChange={(e) => {
										setFiscalIdValue(e.target.value);
									}}
									disabled={loading}
								/>

								<label htmlFor={'organizationType'}>
									{t('AddBillingDataForm.organizationType')}
								</label>
								<Dropdown
									options={getOrganizationTypeOptions()}
									onChange={(e) => {
										setOrganizationTypeValue(e.value);
									}}
									value={organizationTypeValue}
									id={'organizationType'}
									placeholder={t('common.selectOption')}
									disabled={loading}
								/>
							</>
						)}

						<label htmlFor={'billingMethodType'}>
							{t('AddBillingDataForm.billingMethodType')}
						</label>
						<Dropdown
							options={billingMethodTypesOptions}
							onChange={(e) => {
								setBillingMethodTypeOptionValue(e.value);
								setBillingMethodConfigurationObject({});
							}}
							value={billingMethodTypeOptionValue}
							id={'billingMethodType'}
							placeholder={t('common.selectOption')}
							disabled={loading}
						/>
						{!!billingMethodTypeOptionValue &&
							!!billingMethodTypesConfigurationsRef.current[
								billingMethodTypeOptionValue
							] && (
								<BillingDataTypeConfigurationRenderer
									billingDataTypeConfiguration={
										billingMethodTypesConfigurationsRef.current[
											billingMethodTypeOptionValue
										]
									}
									configurationObject={billingMethodConfigurationObject}
									setConfigurationObject={setBillingMethodConfigurationObject}
									disabled={loading}
								/>
							)}

						<FormControlLabel
							control={
								<Checkbox
									checked={dataPrivacyAcceptedValue}
									onChange={(e) => {
										setDataPrivacyAcceptedValue(e.target.checked);
									}}
									name='dataPrivacyAccepted'
								/>
							}
							label={t('AddBillingDataForm.dataPrivacyAccepted')}
							id={'dataPrivacyAccepted'}
						/>

						{!isUSCitizenValue && (
							<>
								<FormControlLabel
									control={
										<Checkbox
											checked={foreignAndExceptedOfUSATaxesAcceptedValue}
											onChange={(e) => {
												setForeignAndExceptedOfUSATaxesAcceptedValue(
													e.target.checked
												);
											}}
											name='foreignAndExceptedOfUSATaxesAccepted'
										/>
									}
									label={t(
										'AddBillingDataForm.foreignAndExceptedOfUSATaxesAccepted'
									)}
									id={'foreignAndExceptedOfUSATaxesAccepted'}
								/>
							</>
						)}

						{!!isUSCitizenValue && (
							<>
								<FormControlLabel
									control={
										<Checkbox
											checked={correctFiscalIdAcceptedValue}
											onChange={(e) => {
												setCorrectFiscalIdAcceptedValue(e.target.checked);
											}}
											name='correctFiscalIdAccepted'
										/>
									}
									label={t('AddBillingDataForm.correctFiscalIdAccepted')}
									id={'correctFiscalIdAccepted'}
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={noRestrictionsDueToLatePaymentsAcceptedValue}
											onChange={(e) => {
												setNoRestrictionsDueToLatePaymentsAcceptedValue(
													e.target.checked
												);
											}}
											name='noRestrictionsDueToLatePaymentsAccepted'
										/>
									}
									label={t(
										'AddBillingDataForm.noRestrictionsDueToLatePaymentsAccepted'
									)}
									id={'noRestrictionsDueToLatePaymentsAccepted'}
								/>

								<FormControlLabel
									control={
										<Checkbox
											checked={
												USCitizenOrOtherRecognisedConditionAcceptedValue
											}
											onChange={(e) => {
												setUSCitizenOrOtherRecognisedConditionAcceptedValue(
													e.target.checked
												);
											}}
											name='USCitizenOrOtherRecognisedConditionAccepted'
										/>
									}
									label={t(
										'AddBillingDataForm.USCitizenOrOtherRecognisedConditionAccepted'
									)}
									id={'USCitizenOrOtherRecognisedConditionAccepted'}
								/>
							</>
						)}

						<label htmlFor={'signature'}>{t('AddBillingDataForm.signature')}</label>
						<input
							id={'signature'}
							type={'text'}
							value={signatureValue}
							onChange={(e) => {
								setSignatureValue(e.target.value);
							}}
							disabled={loading}
						/>

						<Button variant='outlined' color='primary' type={'submit'}>
							{t('AddBillingUserForm.create')}
						</Button>
					</>
				)}
			</form>
		</Container>
	);
};

export default AddBillingDataForm;
