import React from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

const NumberField = ({ value, onChange, disabled, fieldName, min, max }) => {
	const { t, i18n } = useTranslation();

	return (
		<>
			<label htmlFor={fieldName}>{t(`billingMethodsFields.${fieldName}`)}</label>
			<input
				id={fieldName}
				type={'number'}
				value={value}
				min={min}
				max={max}
				onChange={(e) => {
					onChange(e.target.value);
				}}
				disabled={disabled}
			/>
		</>
	);
};

export default NumberField;
