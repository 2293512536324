import React, { useContext, useEffect } from 'react';
import CurrentUserSessionDataContext from '../contexts/CurrentUserSessionDataContext';
import {
	addBillingDataComposer,
	addNewBillingUserToComposer,
	eliminateBillingDataComposer,
	eliminateBillingUserComposer,
	makeBilligDataDefaultComposer,
	setBillingMethodTypesRequested,
	setBillingMethodTypesToComposer,
	setBillingUsersToComposer
} from '../reducers/currentUserSessionDataReducer';
import useAxios from './axios-hooks';

const useCurrentUserSessionData = () => {
	const [
		{
			data: billingMethodTypesData,
			error: errorBillingMethodTypes,
			loading: loadingBillingMethodTypes
		},
		executeGetAvailableBillingMethodTypes
	] = useAxios(
		{
			url: 'getAvailableBillingMethodsTypes',
			method: 'GET'
		},
		{ manual: true }
	);
	const { currentUserSessionData, dispatchCurrentUserSessionData } = useContext(
		CurrentUserSessionDataContext
	);

	useEffect(() => {
		if (billingMethodTypesData) {
			dispatchCurrentUserSessionData(setBillingMethodTypesToComposer(billingMethodTypesData));
		}
	}, [billingMethodTypesData]);

	const setBillingUsersTo = (newBillingUsers) => {
		dispatchCurrentUserSessionData(setBillingUsersToComposer(newBillingUsers));
	};

	const getBillingUsers = () => {
		return currentUserSessionData.billingUsers;
	};

	const getBillingDatas = () => {
		let billingDatas = [];
		currentUserSessionData.billingUsers.forEach((currentBillingUser) => {
			if (currentBillingUser.relatedBillingDatas)
				billingDatas = [...billingDatas, ...currentBillingUser.relatedBillingDatas];
		});

		return billingDatas;
	};
	const addNewBillingUser = (newBillingUser) => {
		dispatchCurrentUserSessionData(addNewBillingUserToComposer(newBillingUser));
	};

	const eliminateBillingUser = (billingUserIdToDelete) => {
		dispatchCurrentUserSessionData(eliminateBillingUserComposer(billingUserIdToDelete));
	};

	const eliminateBillingData = (billingDataIdToDelete) => {
		dispatchCurrentUserSessionData(eliminateBillingDataComposer(billingDataIdToDelete));
	};

	const setBillingMethodTypes = (billingMethodTypes) => {
		dispatchCurrentUserSessionData(setBillingMethodTypesToComposer(billingMethodTypes));
	};

	const getBillingMethodTypes = () => {
		if (
			!currentUserSessionData.billingMethodTypesRequested &&
			!loadingBillingMethodTypes &&
			!billingMethodTypesData
		) {
			executeGetAvailableBillingMethodTypes();
		}
		return currentUserSessionData.billingMethodTypes;
	};

	const addBillingData = (newBillingData) => {
		dispatchCurrentUserSessionData(addBillingDataComposer(newBillingData));
	};

	const makeBilligDataDefault = (newDefaultedBillingDataId) => {
		dispatchCurrentUserSessionData(makeBilligDataDefaultComposer(newDefaultedBillingDataId));
	};

	return {
		makeBilligDataDefault,
		addBillingData,
		setBillingUsersTo,
		getBillingUsers,
		addNewBillingUser,
		eliminateBillingUser,
		getBillingDatas,
		eliminateBillingData,
		setBillingMethodTypes,
		getBillingMethodTypes
	};
};

export default useCurrentUserSessionData;
