const checkIfCountryCodePresentInCountryCodesObjectArray = (
	countryCodeToLook,
	countryCodesArray
) => {
	let wasFound = false;
	if (countryCodesArray) {
		countryCodesArray.forEach((current) => {
			if (current.countryCode === countryCodeToLook) wasFound = true;
		});
	}

	return wasFound;
};
export default checkIfCountryCodePresentInCountryCodesObjectArray;
