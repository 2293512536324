import axios from 'axios';
import getCurrentToken from 'misc/getCurrentToken';
import history from 'router/history';

const instance = axios.create({
	baseURL: 'https://f-safevault-dev.azurewebsites.net/api/'
});
instance.interceptors.request.use(
	function (config) {
		const token = getCurrentToken();
		if (token) {
			config.headers['Authorization'] = 'Bearer ' + token;
		}
		return config;
	},
	function (error) {
		return Promise.reject(error);
	}
);

const createAxiosResponseInterceptor = (axiosInstance) => {
	const interceptor = axiosInstance.interceptors.response.use(
		(response) => {
			// history.push('/test');
			return response;
		},
		(error) => {
			// Reject promise if usual error
			if (
				error.response &&
				error.response.status === 500 &&
				error.response.data &&
				error.response.data.desc &&
				error.response.data.desc.includes('LOGIN_ERROR')
			) {
				history.push('/unauthorized');
				// window.dispatchEvent(new Event(FORCE_LOGOUT_AND_OPEN_LOGIN_POPUP));
			}

			return Promise.reject(error);
		}
	);
};

createAxiosResponseInterceptor(instance);

export default instance;
