const qs = require('qs');

const getCurrentToken = () => {
	const queryStrings = qs.parse(window.location.search.replace('?', ''));
	let token = !!queryStrings && !!queryStrings.token ? queryStrings.token : null;
	if (!token && window.token) token = window.token;
	return token;
};

export default getCurrentToken;
