import React, { useEffect } from 'react';
import history from './history';

import { Router, Route, Switch } from 'react-router';
import CurrentAuthDetails from '../components/CurrentAuthDetails/CurrentAuthDetails';
import TokenizedRoute from './TokenizedRoute';
import Unauthorized from '../components/Unauthorized/Unauthorized';
import UserMainContextRenderer from '../components/UserMainManager/UserMainContextRenderer';

const AppRouter = () => {
	useEffect(() => {
		if (
			!window.location.href.includes('localhost') &&
			!window.location.href.includes('127.0.0.1') &&
			window.location &&
			window.location.search &&
			window.location.search.includes('token')
		) {
			history.push(window.location.pathname.replace(window.location.search, ''));
		}
	}, []);
	return (
		<Router history={history}>
			<Switch>
				<Route path='/unauthorized' component={Unauthorized} />
				<TokenizedRoute path={'/client'} component={UserMainContextRenderer} />
				<TokenizedRoute path='/currentAuthDetails' component={CurrentAuthDetails} />
			</Switch>
		</Router>
	);
};

export default AppRouter;
