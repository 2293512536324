import React, { useEffect, useState } from 'react';
import { Button, Grid, Paper } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';
import BillingDataItemRenderer from '../BillingDataItemRenderer/BillingDataItemRenderer';
import AddBillingDataForm from '../AddBillingDataForm/AddBillingDataForm';
import notifyHeightToParent from '../../misc/notifyHeightToParent';

const BillingDatasManager = () => {
	const { t, i18n } = useTranslation();

	const { getBillingDatas } = useCurrentUserSessionData();

	const [isAddBillingDataFormVisible, setIsAddBillingDataFormVisible] = useState(false);
	useEffect(() => {
		notifyHeightToParent();
	}, [isAddBillingDataFormVisible]);
	return (
		<Paper elevation={3}>
			<h3>{t('BillingDatasManager.title')}</h3>
			<Grid container spacing={2} style={{ padding: '10px' }}>
				{getBillingDatas().map((current) => {
					return <BillingDataItemRenderer billingDataItem={current} key={current._id} />;
				})}
			</Grid>

			<Button
				onClick={() => {
					setIsAddBillingDataFormVisible((prev) => {
						return !prev;
					});
				}}
			>
				{t('BillingDatasManager.createBillingData')}
			</Button>

			{isAddBillingDataFormVisible && (
				<AddBillingDataForm
					onSuccess={() => {
						setIsAddBillingDataFormVisible(false);
					}}
				/>
			)}
		</Paper>
	);
};

export default BillingDatasManager;
