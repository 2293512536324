import React from 'react';
import useAxios from 'hooks/axios-hooks';
import { useEffect } from 'react';

import useCurrentSessionToken from '../../hooks/useCurrentSessionToken';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';
import AddBillingUserForm from '../AddBillingUserForm/AddBillingUserForm';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import BillingUsersManager from '../BillingUsersManager/BillingUsersManager';
import BillingDatasManager from '../BillingDatasManager/BillingDatasManager';
import { Container } from '@material-ui/core';
import notifyHeightToParent from '../../misc/notifyHeightToParent';

const UserMainManager = ({ children }) => {
	const [{ data, loading, error }, refetch] = useAxios('getownbillinguserswithdetailsprotected');
	const currentToken = useCurrentSessionToken();
	const { setBillingUsersTo, getBillingUsers } = useCurrentUserSessionData();
	const { t, i18n } = useTranslation();

	useEffect(() => {
		if (data) {
			setBillingUsersTo(data);
			notifyHeightToParent();
		}
	}, [data]);

	if (loading) {
		return <p>Loading</p>;
	}

	if (error) {
		return <p>Error</p>;
	}
	if (data) {
		return (
			<Container>
				<h1>{t('UserMainManager.title')}</h1>
				<BillingUsersManager />
				<BillingDatasManager />
			</Container>
		);
	}
};

export default UserMainManager;
