import React from "react";
import CurrentSessionContext from "../contexts/CurrentSessionContext";
import {useContext} from "react";

const useCurrentSessionToken = () => {
    const {currentSessionToken} = useContext(CurrentSessionContext)
    return currentSessionToken;
}

export default useCurrentSessionToken;
