import React, { useEffect, useState } from 'react';
import Dropdown from 'react-dropdown';
import { useTranslation } from 'react-i18next';

const ComboField = ({ value, options, onChange, disabled, fieldName }) => {
	const { t, i18n } = useTranslation();
	const [availableOptions, setAvailableOptions] = useState([]);

	useEffect(() => {
		let availableOptionsResult = [];
		if (options) {
			options.forEach((current) => {
				availableOptionsResult = [
					...availableOptionsResult,
					{
						value: current,
						label: t(`billingMethodsFieldsOptions.${current}`)
					}
				];
			});
		}
		setAvailableOptions(availableOptionsResult);
	}, [options]);
	return (
		<>
			<label htmlFor={fieldName}>{t(`billingMethodsFields.${fieldName}`)}</label>
			<Dropdown
				options={availableOptions}
				onChange={(e) => {
					onChange(e.value);
				}}
				value={value}
				id={fieldName}
				placeholder={t('common.selectOption')}
				disabled={disabled}
			/>
		</>
	);
};

export default ComboField;
