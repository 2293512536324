import React, { useState, useMemo, useEffect } from 'react';
import Dropdown from 'react-dropdown';
import 'react-dropdown/style.css';
import countryList from 'react-select-country-list';
import { useTranslation } from 'react-i18next';
import useAxios from 'hooks/axios-hooks';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';
import { Button, Container } from '@material-ui/core';
import { ImagePicker } from 'react-file-picker';

const availableDocumentTypes = [
	{
		value: 'dni',
		label: 'DNI'
	}
];

const defaultDocumentType = availableDocumentTypes[0].value;
const AddBillingUserForm = ({ onSuccess }) => {
	const { t, i18n } = useTranslation();
	const { addNewBillingUser } = useCurrentUserSessionData();
	const countryOptions = useMemo(() => countryList().getData(), []);

	const [firstNameValue, setFirstNameValue] = useState('');
	const [lastNameValue, setLastNameValue] = useState('');
	const [bornDateValue, setBornDateValue] = useState('');
	const [identificationTypeValue, setIdentificationTypeValue] = useState(defaultDocumentType);
	const [identificationNumberValue, setIdentificationNumberValue] = useState('');
	const [countryValue, setCountryValue] = useState(countryOptions[0].value);
	const [frontalId, setFrontalId] = useState(null);
	const [backId, setBackId] = useState(null);
	const [selfieId, setSelfieId] = useState(null);

	const [{ data, error, loading }, executePost] = useAxios(
		{
			url: 'createBillingUser',
			method: 'POST'
		},
		{ manual: true }
	);
	useEffect(() => {
		if (error) {
			alert(t('AddBillingUserForm.error'));
		}
	}, [error]);

	useEffect(() => {
		if (data && data.success && data.newBillingUser) {
			onSuccess();
			addNewBillingUser(data.newBillingUser);
		}
	}, [data]);
	const onSubmit = (e) => {
		const date = new Date(bornDateValue);
		executePost({
			data: {
				bornDate: date.toString(),
				firstName: firstNameValue,
				lastName: lastNameValue,
				identificationNumber: identificationNumberValue,
				identificationType: identificationTypeValue,
				countryCode: countryValue,
				id_front: frontalId,
				id_back: backId,
				id_selfie: selfieId
			}
		});
	};

	return (
		<Container>
			<form
				onSubmit={(e) => {
					e.preventDefault();
				}}
				style={{ display: 'grid' }}
			>
				<label htmlFor={'firstName'}>{t('AddBillingUserForm.firstName')}</label>
				<input
					id={'firstName'}
					type={'text'}
					value={firstNameValue}
					onChange={(e) => {
						setFirstNameValue(e.target.value);
					}}
					disabled={loading}
				/>
				<label htmlFor={'lastName'}>{t('AddBillingUserForm.lastName')}</label>
				<input
					id={'lastName'}
					type={'text'}
					value={lastNameValue}
					onChange={(e) => {
						setLastNameValue(e.target.value);
					}}
					disabled={loading}
				/>
				<label htmlFor={'bornDate'}>{t('AddBillingUserForm.bornDate')}</label>
				<input
					id={'bornDate'}
					type={'date'}
					value={bornDateValue}
					onChange={(e) => {
						setBornDateValue(e.target.value);
					}}
					disabled={loading}
				/>
				<label htmlFor={'documentType'}>{t('AddBillingUserForm.documentType')}</label>
				<Dropdown
					options={availableDocumentTypes}
					onChange={(e) => {
						setIdentificationTypeValue(e.value);
					}}
					value={identificationTypeValue}
					id={'documentType'}
					placeholder={t('common.selectOption')}
					disabled={loading}
				/>
				<label htmlFor={'documentNumber'}>{t('AddBillingUserForm.documentNumber')}</label>
				<input
					id={'documentNumber'}
					type={'text'}
					value={identificationNumberValue}
					onChange={(e) => {
						setIdentificationNumberValue(e.target.value);
					}}
					disabled={loading}
				/>

				<label htmlFor={'country'}>{t('AddBillingUserForm.country')}</label>
				<Dropdown
					options={countryOptions}
					onChange={(e) => {
						setCountryValue(e.value);
					}}
					value={countryValue}
					id={'country'}
					placeholder={t('common.selectOption')}
					disabled={loading}
				/>

				<label htmlFor={'country'}>{t('AddBillingUserForm.frontId')}</label>
				{frontalId && (
					<img src={frontalId} style={{ maxHeight: '200px', maxWidth: '200px' }} />
				)}
				<ImagePicker
					extensions={['jpg', 'jpeg', 'png']}
					dims={{ minWidth: 100, maxWidth: 3000, minHeight: 100, maxHeight: 3000 }}
					onChange={(base64) => {
						setFrontalId(base64);
					}}
					onError={(errorMsg) => {
						alert(errorMsg);
					}}
				>
					<button>{t('AddBillingUserForm.fileSelect')}</button>
				</ImagePicker>

				<label htmlFor={'country'}>{t('AddBillingUserForm.backId')}</label>
				{backId && <img src={backId} style={{ maxHeight: '200px', maxWidth: '200px' }} />}
				<ImagePicker
					extensions={['jpg', 'jpeg', 'png']}
					dims={{ minWidth: 100, maxWidth: 3000, minHeight: 100, maxHeight: 3000 }}
					onChange={(base64) => {
						setBackId(base64);
					}}
					onError={(errorMsg) => {
						alert(errorMsg);
					}}
				>
					<button>{t('AddBillingUserForm.fileSelect')}</button>
				</ImagePicker>

				<label htmlFor={'country'}>{t('AddBillingUserForm.selfieId')}</label>
				{selfieId && (
					<img src={selfieId} style={{ maxHeight: '200px', maxWidth: '200px' }} />
				)}
				<ImagePicker
					extensions={['jpg', 'jpeg', 'png']}
					dims={{ minWidth: 100, maxWidth: 3000, minHeight: 100, maxHeight: 3000 }}
					onChange={(base64) => {
						setSelfieId(base64);
					}}
					onError={(errorMsg) => {
						alert(errorMsg);
					}}
				>
					<button>{t('AddBillingUserForm.fileSelect')}</button>
				</ImagePicker>
				<Button
					variant='outlined'
					color='primary'
					type={'submit'}
					onClick={() => {
						onSubmit();
					}}
				>
					{t('AddBillingUserForm.create')}
				</Button>
			</form>
		</Container>
	);
};
export default AddBillingUserForm;
