import React, { useEffect } from 'react';
import { Button, Card, CardActions, CardContent, Grid, Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import BillingDataItemBillingConfigurationItemRenderer from './BillingDataItemBillingConfigurationItemRenderer';
import { useTranslation } from 'react-i18next';
import useAxios from '../../hooks/axios-hooks';
import useCurrentUserSessionData from '../../hooks/useCurrentUserSessionData';

const useStyles = makeStyles({
	root: {
		minWidth: 275
	},
	bullet: {
		display: 'inline-block',
		margin: '0 2px',
		transform: 'scale(0.8)'
	},
	title: {
		fontSize: 14
	},
	pos: {
		marginBottom: 12
	}
});

const BillingDataItemRenderer = ({ billingDataItem }) => {
	const classes = useStyles();
	const { t, i18n } = useTranslation();
	const { eliminateBillingData, makeBilligDataDefault } = useCurrentUserSessionData();
	const [
		{
			data: dataDisableBillingData,
			error: errorDisableBillingData,
			loading: loadingDisableBillingData
		},
		executePostDisableBillingData
	] = useAxios(
		{
			url: 'disableBillingData',
			method: 'POST'
		},
		{ manual: true }
	);

	const [
		{
			data: dataMakeDefaultBillingData,
			error: errorMakeDefaultBillingData,
			loading: loadingMakeDefaultBillingData
		},
		executePostMakeDefaultBillingData
	] = useAxios(
		{
			url: 'changeBillingDataToDefaultTrue',
			method: 'POST'
		},
		{ manual: true }
	);

	useEffect(() => {
		if (dataMakeDefaultBillingData && dataMakeDefaultBillingData.success) {
			makeBilligDataDefault(dataMakeDefaultBillingData.newDefaultedBillingDataId);
		}
	}, [dataMakeDefaultBillingData]);

	useEffect(() => {
		if (dataDisableBillingData && dataDisableBillingData.success) {
			eliminateBillingData(dataDisableBillingData.disabledBillingDataId);
		}
	}, [dataDisableBillingData]);

	useEffect(() => {
		if (errorMakeDefaultBillingData) {
			alert(t('common.error'));
		}
	}, [errorMakeDefaultBillingData]);
	useEffect(() => {
		if (errorDisableBillingData) {
			alert(t('common.error'));
		}
	}, [errorDisableBillingData]);
	const disableBillingData = () => {
		if (!loadingDisableBillingData)
			executePostDisableBillingData({
				data: {
					id: billingDataItem._id
				}
			});
	};

	const makeDefaultBillingData = () => {
		if (!loadingMakeDefaultBillingData) {
			executePostMakeDefaultBillingData({
				data: {
					id: billingDataItem._id
				}
			});
		}
	};
	return (
		<Grid item xs={4} key={billingDataItem._id}>
			<Card className={classes.root}>
				<CardContent>
					<Typography className={classes.title} color='textSecondary' gutterBottom>
						{t(`billingMethods.${billingDataItem.billingMethodTypeName}`)}
						{!!billingDataItem && !!billingDataItem.isDefault && (
							<> - {t('BillingDataItemRenderer.default')}</>
						)}
					</Typography>

					{billingDataItem.billingMethodConfiguration.map((current, n) => {
						return (
							<BillingDataItemBillingConfigurationItemRenderer
								key={billingDataItem._id + ' ' + n}
								billingConfigurationToRender={current}
							/>
						);
					})}
				</CardContent>
				<CardActions>
					<Button
						size='small'
						onClick={() => {
							disableBillingData();
						}}
						disabled={loadingDisableBillingData}
					>
						{t('actions.delete')}
					</Button>
					{!!billingDataItem && !billingDataItem.isDefault && (
						<Button
							size={'small'}
							onClick={() => {
								makeDefaultBillingData();
							}}
						>
							{t('actions.makeDefault')}
						</Button>
					)}
				</CardActions>
			</Card>
		</Grid>
	);
};

export default BillingDataItemRenderer;
