const INITIAL_CURRENT_USER_SESSION_DATA_REDUCER_STATE = {
	billingUsers: [],
	billingMethodTypes: [],
	billingMethodTypesRequested: false
};
const currentUserSessionStreamDataReducer = (state, action) => {
	switch (action.type) {
		case 'SET_BILLINGMETHODSTYPES_TO':
			return {
				...state,
				billingMethodTypes: action.billingMethodTypes,
				billingMethodTypesRequested: true
			};
			break;

		case 'SET_BILLINGUSERS_TO':
			return { ...state, billingUsers: action.billingUsers };
			break;

		case 'ADD_NEW_BILLING_USER':
			return { ...state, billingUsers: [...state.billingUsers, action.newBillingUser] };
			break;

		case 'ELIMINATE_BILLING_USER':
			return {
				...state,
				billingUsers: [
					...state.billingUsers.filter((current) => {
						return current._id != action.billingUserIdToDelete;
					})
				]
			};
			break;

		case 'ELIMINATE_BILLING_DATA':
			{
				let resultingBillingUsers = [];
				state.billingUsers.forEach((currentBillingUser) => {
					resultingBillingUsers = [
						...resultingBillingUsers,
						{
							...currentBillingUser,
							relatedBillingDatas: currentBillingUser.relatedBillingDatas.filter(
								(currentBillingDataOfCurrentBillingUser) => {
									return (
										currentBillingDataOfCurrentBillingUser._id !==
										action.billingDataIdToDelete
									);
								}
							)
						}
					];
				});
				return {
					...state,
					billingUsers: resultingBillingUsers
				};
			}
			break;

		case 'ADD_BILLING_DATA':
			{
				const ownerIdToAddBillingData = action.newBillingData.owner;

				let resultingBillingUsers = [];

				state.billingUsers.forEach((current) => {
					if (current._id !== ownerIdToAddBillingData) {
						resultingBillingUsers = [...resultingBillingUsers, current];
					} else {
						resultingBillingUsers = [
							...resultingBillingUsers,
							{
								...current,
								relatedBillingDatas: [
									...current.relatedBillingDatas,
									action.newBillingData
								]
							}
						];
					}
				});

				return { ...state, billingUsers: resultingBillingUsers };
			}
			break;

		case 'MAKE_BILLING_DATA_DEFAULT':
			{
				let resultingBillingUsers = [];
				state.billingUsers.forEach((current) => {
					let newBillingDatasOfCurrentBillingUser = [];
					current.relatedBillingDatas.forEach((currentRelatedBillingData) => {
						if (currentRelatedBillingData._id === action.newDefaultedBillingDataId) {
							newBillingDatasOfCurrentBillingUser = [
								...newBillingDatasOfCurrentBillingUser,
								{ ...currentRelatedBillingData, isDefault: true }
							];
						} else {
							newBillingDatasOfCurrentBillingUser = [
								...newBillingDatasOfCurrentBillingUser,
								{ ...currentRelatedBillingData, isDefault: false }
							];
						}
					});
					resultingBillingUsers = [
						...resultingBillingUsers,
						{ ...current, relatedBillingDatas: newBillingDatasOfCurrentBillingUser }
					];
				});

				return { ...state, billingUsers: resultingBillingUsers };
			}
			break;
	}
};

const makeBilligDataDefaultComposer = (newDefaultedBillingDataId) => {
	return {
		type: 'MAKE_BILLING_DATA_DEFAULT',
		newDefaultedBillingDataId: newDefaultedBillingDataId
	};
};
const addBillingDataComposer = (newBillingData) => {
	return {
		type: 'ADD_BILLING_DATA',
		newBillingData: newBillingData
	};
};
const setBillingMethodTypesToComposer = (billingMethodTypes) => {
	return {
		type: 'SET_BILLINGMETHODSTYPES_TO',
		billingMethodTypes: billingMethodTypes
	};
};
const eliminateBillingDataComposer = (billingDataIdToDelete) => {
	return {
		type: 'ELIMINATE_BILLING_DATA',
		billingDataIdToDelete: billingDataIdToDelete
	};
};
const eliminateBillingUserComposer = (billingUserIdToDelete) => {
	return {
		type: 'ELIMINATE_BILLING_USER',
		billingUserIdToDelete: billingUserIdToDelete
	};
};
const addNewBillingUserToComposer = (newBillingUser) => {
	return { type: 'ADD_NEW_BILLING_USER', newBillingUser: newBillingUser };
};
const setBillingUsersToComposer = (newBillingUsers) => {
	return {
		type: 'SET_BILLINGUSERS_TO',
		billingUsers: newBillingUsers
	};
};

export {
	INITIAL_CURRENT_USER_SESSION_DATA_REDUCER_STATE,
	currentUserSessionStreamDataReducer,
	setBillingUsersToComposer,
	addNewBillingUserToComposer,
	eliminateBillingUserComposer,
	eliminateBillingDataComposer,
	setBillingMethodTypesToComposer,
	addBillingDataComposer,
	makeBilligDataDefaultComposer
};
