import React from 'react';
import useCurrentSessionToken from '../hooks/useCurrentSessionToken';
import { Redirect, Route } from 'react-router-dom';

const TokenizedRoute = ({ component, ...rest }) => {
	const currentToken = useCurrentSessionToken();

	if (!currentToken) {
		return <Redirect to='/unauthorized' />;
	} else {
		return <Route component={component} {...rest} />;
	}
};

export default TokenizedRoute;
