const notifyHeightToParent = () => {
	setTimeout(() => {
		window.parent.postMessage(
			['setHeight', document.getElementsByTagName('html')[0].scrollHeight],
			'*'
		);
	}, 500);
};

export default notifyHeightToParent;
