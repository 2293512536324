import React from 'react';
import getCurrentToken from '../../misc/getCurrentToken';
import { useState, useEffect } from 'react';
import CurrentSessionContext from '../CurrentSessionContext';
const CurrentSessionProvider = ({ children }) => {
	const [currentSessionToken, setCurrentSessionToken] = useState(getCurrentToken());

	useEffect(() => {
		window.token = currentSessionToken;
	}, [currentSessionToken]);
	return (
		<CurrentSessionContext.Provider value={{ currentSessionToken }}>
			{children}
		</CurrentSessionContext.Provider>
	);
};

export default CurrentSessionProvider;
